import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Home() {
    return (
        <>
            <Header />
            <div>
                <div className="xl:mx-auto xl:container pt-12">
                    <h1 className="text-4xl font-bold text-color-black lg:mb-36 mb-16 xl:px-0 px-4">Contact Us</h1>
                </div>
                <div className="relative">
                    <div className="bg-peach h-full w-1/2 absolute md:block hidden" />
                    <div className="xl:mx-auto xl:px-0 px-4 xl:container bg-peach py-16 relative">
                        <div className="lg:pr-72">
                            <p className="text-base lg:text-2xl font-light leading-10 text-color-black">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc, diam dui a a, massa id condimentum cursus. Consequat quis nulla netus lorem. Sagittis mauris fringilla adipiscing nunc risus est. Egestas urna turpis aliquam quam aenean a. Praesent elit mattis dignissim tempus nec aenean.</p>
                            <p />
                            <p className="lg:pt-16 pt-8 text-base lg:text-2xl font-light leading-10 text-color-black">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc, diam dui a a, massa id condimentum cursus. Consequat quis nulla netus lorem. Sagittis mauris fringilla adipiscing nunc risus est. Egestas urna turpis aliquam quam aenean a. Praesent elit mattis dignissim tempus nec aenean.</p>
                            <p />
                            <div className="lg:mt-20 mt-10">
                                <div className="flex items-center">
                                    <p className="text-base lg:text-2xl font-bold leading-10 text-color-black w-24">Email:</p>
                                    <p className="text-base lg:text-2xl font-light leading-10 text-color-black">alan@angrymonkeys.com.au</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
